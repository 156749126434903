<template>
  <v-row>
    <v-col cols="12">
      <loading-spinner v-model="dataLoading" />
      <v-simple-table v-if="supportTickets.length" dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th> Subject </th>
            <th> Opened </th>
            <th> Status </th>
            <th> actions </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(ticket, index) in supportTickets" :key="'orders'+index">
            <td>{{ ticket.subject }}</td>
            <td>{{ ticket.created_at | date }}</td>
            <td v-html="statusBadge(ticket.status)"></td>
            <td>
              <v-btn :to="{name: 'ticketDetails', params: { ticket : ticket.id }}" color="secondary">View</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        <v-sheet>
          <v-alert
            prominent
            text
            type="info"
          >
            <span>You didn't have any support request</span>
          </v-alert>
        </v-sheet>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import LoadingSpinner from '@/components/Utils/LoadingSpinner';

export default {
  name: 'SupportTicket',

  components: { LoadingSpinner },

  // props: {
  //   user: {
  //     type: Object,
  //   }
  // },

  computed: {
    ...mapGetters({
      supportTickets: 'user/userTickets',
      dataLoading: 'user/loading'
    }),
  },

  methods: {

    ...mapActions({
      getSupportTickets: 'user/getUserTicket'
    }),
  },

  async mounted() {

    if(this.supportTickets.length){
      return;
    }

    await this.getSupportTickets();
  }
};
</script>

<style scoped>

</style>
